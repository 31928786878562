// plugins/connection.js
import { reactive } from 'vue'

export default defineNuxtPlugin(() => {
  // Only run on the client side
  if (process.client && navigator.connection) {
    const connectionState = reactive({
      effectiveType: navigator.connection.effectiveType,
    });
    
    // Update the reactive state when the connection changes
    navigator.connection.addEventListener('change', () => {
      connectionState.effectiveType = navigator.connection.effectiveType;
    });
    
    return {
      provide: {
        connection: connectionState,
      }
    }
  }
  
  // Fallback in case the API isn't available
  return {
    provide: {
      connection: reactive({ effectiveType: 'unknown' }),
    }
  }
});
