import { createApp, defineAsyncComponent } from 'vue';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('processlinks', {
    mounted(el) {
      // Dynamically load the NuxtLink component
      const SmartLink = defineAsyncComponent(() => import('~/components/SmartLink.vue'));
      // Replace all <a> tags with the <NuxtLink> component
      el.querySelectorAll('a').forEach((node) => {
        const props = {
          href: node.getAttribute('href'),
          innerText: node.innerText,
          target: '_blank',
          rel: node.getAttribute('rel'),
        };

        // Rendering hte component
        const app = createApp(SmartLink, props);
        const placeholder = document.createElement('span'); // Placeholde for the mount, span cuz it is inline
        app.mount(placeholder); // Mount the app to the placeholder

        node.replaceWith(placeholder); // Replace <a> with the new rendered component
      });
    },
  });
});