<!-- frontend/src/components/Layout/Footer.vue -->
<template>
  <footer class="text-gray-600 pb-32 md:pb-0 md:px-16">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8 md:px:16">
      <div class="md:flex md:justify-between">
        <div class="mb-6 md:mb-0">
          <NuxtLinkLocale to="/" class="flex items-center text-gray-500">
            <img src="/img/logo.svg" class="h-8 me-3" alt="TourScanner Logo" />
            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>
          </NuxtLinkLocale>
          <div class="flex items-center mt-4">
            <span class="mr-3 font-medium text-gray-900 dark:text-gray-300">{{ $t('Theme:') }}</span>
            <div
              class="relative inline-flex items-center p-3 rounded-full bg-gray-200 dark:bg-gray-700 cursor-pointer hover:shadow-md hover:dark:shadow-md dark:shadow-white"
              @click="isDark = !isDark"
            >
              <ClientOnly>
                <img
                  :src="isDark ? '/img/icons/dark_theme.svg' : '/img/icons/light_theme.svg'"
                  alt="Theme Toggle"
                  class="w-5 h-5 dark:text-gray-400 dark:invert"
                />
                <template #fallback>
                  <img
                    :src="'/img/icons/light_theme.svg'"
                    alt="Theme Toggle"
                    class="w-5 h-5 dark:text-gray-400 dark:invert"
                  />
                </template>
              </ClientOnly>
            </div>
          </div>
        </div>
        <div class="md:grid-cols-none gap-8 sm:gap-6 sm:grid-cols-3 mx-0 sm:mx-5 hidden">
          <div>
            <span class="mb-0 md:mb-6 font-semibold text-gray-900 dark:text-white block">
              {{ $t('Mobile') }}
            </span>
            <div
              class="flex flex-row md:flex-col justify-center items-center space-y-0 space-x-5 md:space-x-0 md:space-y-5 mb-6 md:mb-0 mt-2"
            >
              <a
                href="https://apps.apple.com/us/app/tourscanner-compare-tours/id1496550592"
                class="w-2/4 sm:w-40 md:w-32"
              >
                <img :src="`/img/appstores-badges/${currentLang}/apple.svg`" alt="Google Play" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=co.tourscanner.tourscannerwebview&hl=en&pli=1"
                class="w-2/4 sm:w-40 md:w-32"
              >
                <img :src="`/img/appstores-badges/${currentLang}/google.svg`" alt="App Store" class="w-full" />
              </a>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 text-gray-500">
          <div>
            <span class="mb-6 block font-semibold text-gray-900 dark:text-white">{{ $t('Support') }}</span>
            <ul class="text-gray-500 dark:text-gray-300 mt-2 space-y-1">
              <li>
                <NuxtLinkLocale to="/faq" class="hover:underline">{{ $t('FAQ') }}</NuxtLinkLocale>
              </li>
              <li>
                <NuxtLinkLocale to="/privacy-policy" class="hover:underline">{{ $t('Privacy Policy') }}</NuxtLinkLocale>
              </li>
              <li>
                <NuxtLinkLocale to="/cookies-policy" class="hover:underline">{{ $t('Cookie Policy') }}</NuxtLinkLocale>
              </li>
              <li>
                <NuxtLinkLocale to="/contact-us" class="hover:underline">{{ $t('Contact Us') }}</NuxtLinkLocale>
              </li>
              <li>
                <NuxtLinkLocale to="/terms-of-service" class="hover:underline">{{
                  $t('Terms of Service')
                }}</NuxtLinkLocale>
              </li>
            </ul>
          </div>
          <div>
            <span class="mb-6 block font-semibold text-gray-900 dark:text-white">{{ $t('Company') }}</span>
            <ul class="text-gray-500 dark:text-gray-300 mt-2 space-y-1">
              <li>
                <NuxtLinkLocale to="/about-us" class="hover:underline">{{ $t('About Us') }}</NuxtLinkLocale>
              </li>
              <li>
                <NuxtLinkLocale to="/destinations" class="hover:underline">{{ $t('Destinations') }}</NuxtLinkLocale>
              </li>
              <li>
                <NuxtLinkLocale to="/company-details" class="hover:underline">{{
                  $t('Company Details')
                }}</NuxtLinkLocale>
              </li>
            </ul>
          </div>
          <div>
            <span class="mb-6 block font-semibold text-gray-900 dark:text-white">
              {{ $t('Work with us') }}
            </span>
            <ul class="text-gray-500 dark:text-gray-300 mt-2 space-y-1">
              <li>
                <NuxtLinkLocale to="/partner/list-your-experience" class="hover:underline">{{
                  $t('List your Experience')
                }}</NuxtLinkLocale>
              </li>
              <li>
                <a href="https://tourscanner.tapfiliate.com/" target="_blank" class="hover:underline">{{
                  $t('Affiliation Program')
                }}</a>
              </li>
              <li>
                <a href="https://wild.tourscanner.com" target="_blank" class="hover:underline">TourScanner Wild</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div class="sm:flex sm:items-center sm:justify-between">
        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-300">© TourScanner 2024 </span>
        <div class="flex mt-4 sm:justify-center sm:mt-0 space-x-5">
          <a href="https://www.facebook.com/tourscanner">
            <img class="icons-new" src="/img/icons/facebook.svg" />
            <span class="sr-only">{{ $t('Facebook Page') }}</span>
          </a>
          <a href="https://www.instagram.com/tourscanner/">
            <img class="icons-new" src="/img/icons/instagram.svg" />
            <span class="sr-only">{{ $t('Instagram') }}</span>
          </a>
          <a href="https://x.com/TourScanner_com">
            <img class="icons-new" src="/img/icons/x.svg" />
            <span class="sr-only">{{ $t('X') }}</span>
          </a>
          <a href="https://www.linkedin.com/company/tourscanner/">
            <img class="icons-new" src="/img/icons/linkedin.svg" />
            <span class="sr-only">{{ $t('LinkedIn account') }}</span>
          </a>
          <a href="https://www.pinterest.com/TourScanner/">
            <img class="icons-new" src="/img/icons/pinterest.svg" />
            <span class="sr-only">{{ $t('Pinterest account') }}</span>
          </a>
          <a href="https://www.youtube.com/@TourScanner.official/">
            <img class="icons-new" src="/img/icons/youtube.svg" />
            <span class="sr-only">{{ $t('YouTube') }}</span>
          </a>
          <a href="https://www.tiktok.com/@tourscanner.com">
            <img class="icons-new" src="/img/icons/tiktok.svg" />
            <span class="sr-only">{{ $t('TikTok') }}</span>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
a {
  @apply text-gray-300;
  @apply text-gray-600;
  @apply hover:text-gray-500;
  @apply dark:text-gray-300;
  @apply dark:hover:text-gray-400;
}
.icons-new {
  @apply w-5 h-5 contrast-100 brightness-0 dark:invert dark:contrast-0 dark:brightness-100;
}

li {
  @apply text-gray-600;
  @apply hover:text-gray-500;
  @apply dark:text-gray-600;
  @apply dark:hover:text-gray-500;
  @apply text-md;
}
</style>

<script setup>
const { locale } = useI18n();
const currentLang = ref(locale.value.split('-')[0]);
const { t } = useI18n();

const colorMode = useColorMode();
const isDark = computed({
  get() {
    return colorMode.value === 'dark';
  },
  set() {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark';
  },
});
</script>
