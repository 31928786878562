<template>
  <img ref="imgRef" :loading="lazyLoadImg ? 'lazy' : 'eager'" :src="src" :alt="alt" :srcset="srcset" @load="emits('imgLoad')" />
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue';

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: false,
  },
  ratio: {
    type: Number,
    required: false,
  },
  lazyLoadImg: {
    type: Boolean,
    required: false,
    default: true,
  },
  preload: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const imgRef = ref(null);
const srcset = ref('');
const widths = ref([150, 180, 210, 300, 360, 420, 588, 720, 1176, 1440]);
const default_width = ref(300);
const default_url = ref('/img/placeholder.png');

const { $connection: connection } = useNuxtApp();
const emits = defineEmits(['imgLoad']);

// Create a local ref to track the last effectiveType we acted upon
const lastEffectiveType = ref(connection.effectiveType);

// Define an order for effective types
const speedOrder = {
  'slow-2g': 4 / 5,
  '2g': 4 / 4,
  '3g': 4 / 3,
  '4g': 4 / 2,
}

const parseImageUrl = (url) => {
  if (!url) return;

  if (url.includes('tourscanner.com')) {
    if (url.includes('blog/wp-content/uploads/')) formatTourscannerImg(url);
    else default_url.value = url;

    return;
  }

  formatProviderImg(url);
};

const addPreloadLink = (url) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'image';
  link.href = url;
  document.head.appendChild(link);
};

function get_declared_width_ratio(){
  // lower for higher res
  if(!speedOrder[lastEffectiveType.value]) return 0.5;

  return 1 / speedOrder[lastEffectiveType.value];
}

const makeDynamicSrcset = (parametrized_url, widths, format_callback = null) => {
  let newSrcset = '';
  let height = null;
  const declared_width_ratio = get_declared_width_ratio();

  if (!format_callback)
    format_callback = function (parametrized_url, width, height = null) {
      parametrized_url = parametrized_url.replace('[w]', width);
      if (height) parametrized_url = parametrized_url.replace('[h]', height);

      return parametrized_url;
    };

  widths.forEach((width) => {
    if (props.ratio) height = Math.round(width * props.ratio);

    let formatted_url = format_callback(parametrized_url, width, height);

    newSrcset = newSrcset.concat(formatted_url, ' ', width * declared_width_ratio, 'w, ');

    if (width === default_width.value) default_url.value = formatted_url;
  });

  srcset.value = newSrcset.slice(0, -2);
};

const resizeViatorImage = (url, width, height = null) => {
  const regex = /spp-(\w{7})/;
  if (width <= 200 && height <= 200) return url.replace(regex, 'spp-200x200');
  if (width <= 400 && height <= 400) return url.replace(regex, 'spp-400x400');
  if (width <= 674 && height <= 446) return url.replace(regex, 'spp-674x446');
  return url.replace(regex, 'spp-720x480');
};

const resizeGygImage = (url, width, height = null) => {
  // https://cdn.getyourguide.com/img/tour/64bfa63ad453a.jpeg/58.jpg
  let best_format = '58.jpg';

  if (width <= 90 && height <= 90) best_format = '52'; // 90x90
  else if (width <= 180 && height <= 180) best_format = '53'; // 180x180
  else if (width <= 320 && height <= 290) best_format = '54'; // 320x290
  else if (width <= 480 && height <= 320) best_format = '60'; // 480x320
  else if (width <= 640 && height <= 580) best_format = '55'; // 640x580
  else if (width <= 768 && height <= 696) best_format = '63'; // 768x696
  else best_format = '74'; // 1102x735

  return url.replace('58.jpg', best_format + '.jpg');
};

const formatHelloticketsImage = (url) => {
  /*  from:
      https://res.cloudinary.com/instant-tickets/image/upload/v1468950375/rcmx4cjop1wenlxrrgmf.jpg
      to:
      https://res.cloudinary.com/instant-tickets/image/upload/c_limit,h_600/v1468950375/rcmx4cjop1wenlxrrgmf.jpg */

  var split_part = '/image/upload/';
  var parsedUrl = url;
  var res = url.split(split_part);
  if (res.length > 1) {
    parsedUrl = res[0] + split_part + 'c_limit,w_[w]' + (props.ratio ? '&h=[h]' : '') + '/' + res[1];
  }
  return parsedUrl;
};

const formatTiqetsImage = (url) => {
  /* Tiqets pictures pattern
   https://aws-tiqets-cdn.imgix.net/images/content/f56fae78122247d799ec87d48b156360.jpg?auto=format&fit=crop&h=250&ixlib=python-1.1.2&q=70&w=250&s=56d6c4dd7c5632f5a650f970e891a2c2 */
  url = url.replace('&h=250', '');
  url = url.replace('&w=250', '');
  return url + '&w=[w]' + (props.ratio ? '&h=[h]' : '');

  /* url = url.replace('&fit=crop', '&fit=clip'); */
};

const formatMusementImage = (url) => {
  return formatImgixImage(url.replace(/\?.*$/, ''));
};

const formatImgixImage = (url) => {
  const paramChar = url.indexOf('?') !== -1 ? '&' : '?';

  return url + paramChar + 'fit=crop&w=[w]' + (props.ratio ? '&h=[h]' : '');
};

const formatKlookImage = (url) => {
  url = url.replace(/\bw_[^\/,]+\b/, 'w_[w]');
  if (props.ratio) url = url.replace(/\bh_[^\/,]+\b/, 'h_[h]');
  return url;
};

const formatFreedomeImage = (url) => {
  if (url.indexOf('?') !== -1) url += '&width=[w]';
  else url += '?width=[w]';

  if (props.ratio) url += '&height=[h]';

  return url;
};

const formatProviderImg = (url) => {
  let format_callback = null;

  if (!url.startsWith('https') && url.startsWith('http')) {
    url = 'https' + url.slice(4);
  }

  if (url.indexOf('media-cdn.tripadvisor') !== -1) {
    format_callback = resizeViatorImage;
  } else if (url.indexOf('cdn.getyourguide') !== -1) {
    format_callback = resizeGygImage;
  } else if (url.indexOf('//aws-tiqets-cdn') !== -1) {
    url = formatTiqetsImage(url);
  } else if (url.indexOf('cdn.freedome') !== -1) {
    url = formatFreedomeImage(url);
  } else if (url.indexOf('res.cloudinary.com') !== -1) {
    url = formatHelloticketsImage(url);
  } else if (
    url.indexOf('//cdn-imgix') !== -1 ||
    url.indexOf('imgix.net') !== -1 ||
    url.indexOf('images.musement') !== -1
  ) {
    if (url.indexOf('images.musement') !== -1) url = formatMusementImage(url);
    else url = formatImgixImage(url);
  } else if (url.indexOf('res.klook.com') !== -1) {
    url = formatKlookImage(url);
  } else {
    // TODO find out who this is and if we still use them
    url = url.replace('graphicslib/', 'graphicslib/thumbs360x240/');

    url = url.replace('splice-spp-674x446/', 'splice-spp-360x240/');
  }

  makeDynamicSrcset(url, widths.value, format_callback);
};

const formatTourscannerImg = (WPUrl) => {
  // replace the WordPress URL with the Cloudflare CDN URL
  const CDNUrl = WPUrl.replace('blog/wp-content/uploads/', 'images/');
  /* Add public variant to src and create srcset and sizes attributes (only for raster images).
      We have variants on Cloudflare at the following widths:
      150
      180
      210
      300
      360
      420
      588
      720
      1176
      1440
      Each variant has a max height of twice its width, to accommodate different image ratios.
      The src attribute acts as a fallback in case srcset fails for some reason. */
  default_url.value = CDNUrl.concat('/public');

  if (!WPUrl.includes('.svg')) {
    makeDynamicSrcset(CDNUrl.concat('/w[w]'), widths.value);
    // sizes = '(max-width: 500px) 500px, (max-width: 800px) 800px, 1280px';
  }
};

onMounted(() => {
  // hack to bypass node loading issues and the 800 javascript requests that need to run before the plugin can properly execute
  //console.log('lastEffectiveType.value', lastEffectiveType.value);
  //console.log('window.__CONNECTION_EFFECTIVE_TYPE__', window.__CONNECTION_EFFECTIVE_TYPE__);
  if (lastEffectiveType.value === 'unknown' && window.__CONNECTION_EFFECTIVE_TYPE__ !== 'unknown') {
    lastEffectiveType.value = window.__CONNECTION_EFFECTIVE_TYPE__;
    parseImageUrl(props.src);
  }

  if (props.preload) addPreloadLink(props.src);
  if (imgRef.value.complete) emits('imgLoad');
});

watch(
  () => props.src,
  (r) => {
    parseImageUrl(props.src);
  },
  { immediate: true },
);

// Watch for changes in the connection effectiveType
watch(
  () => connection.effectiveType,
  (newType) => {
    //console.log('lastEffectiveType.value', lastEffectiveType.value);
    //console.log('newType', newType);
    if (!speedOrder[newType]) return;

    if (speedOrder[lastEffectiveType.value] >= speedOrder[newType]) {
      // If the connection downgrades, do nothing.
      return;
    }
    // Only trigger if the connection upgrades (e.g. 2g → 3g or 3g → 4g)
    lastEffectiveType.value = newType;
    // Re-run the logic to load a higher-quality image
    //console.log('parseImageUrl update');
    parseImageUrl(props.src);
  }
);
</script>