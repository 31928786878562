import { createI18n } from 'vue-i18n';

import deTranslations from '~/assets/translations/de.json';
import enTranslations from '~/assets/translations/en.json';
import esTranslations from '~/assets/translations/es.json';
import frTranslations from '~/assets/translations/fr.json';
import itTranslations from '~/assets/translations/it.json';
import nlTranslations from '~/assets/translations/nl.json';
import ptTranslations from '~/assets/translations/pt.json';

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: { ...enTranslations },
    it: { ...itTranslations },
    fr: { ...frTranslations },
    de: { ...deTranslations },
    es: { ...esTranslations },
    nl: { ...nlTranslations },
    pt: { ...ptTranslations },
  },
});

export function i18n_init(locale, is_explicit = false) {
  const local_i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
      en: { ...enTranslations },
      it: { ...itTranslations },
      fr: { ...frTranslations },
      de: { ...deTranslations },
      es: { ...esTranslations },
      nl: { ...nlTranslations },
      pt: { ...ptTranslations },
    },
  });

  const i18n_interface = {
    locale: 'en',
    t: null,
    is_explicit_locale: false,

    setLocale(locale, is_explicit = false) {
      if (!locale || typeof locale !== 'string') {
        console.info('Invalid locale provided:', locale); // probably fixed in PR, override and restore the warning
        locale = 'en';
      }

      // Normalize locale to lowercase
      locale = locale.toLowerCase();

      // Validate if locale is supported
      const supportedLocales = ['en', 'it', 'fr', 'de', 'es', 'nl', 'pt'];
      if (!supportedLocales.includes(locale)) {
        console.warn(`Unsupported locale: ${locale}, falling back to 'en'`);
        locale = 'en';
      }

      // Update Vue i18n instance
      local_i18n.global.locale.value = locale;

      // Update interface properties
      this.locale = locale;
      this.t = local_i18n.global.t;
      this.is_explicit_locale = is_explicit;
    },

    translate(key, params = {}) {
      try {
        if (!this.t) {
          console.warn('Translation function not initialized');
          return key;
        }
        return this.t(key, params);
      } catch (error) {
        console.error(`Translation error for key "${key}":`, error);
        return key;
      }
    },
  }

  i18n_interface.setLocale(locale, is_explicit);

  return i18n_interface;
}

// Export the i18n instance for use in Vue components
export default i18n;
